
export default {
  props: {
    text: String,
    size: {
      type: String,
      default: 'lg'
    }
  }
}
