


  export default {

    props: [ 'tree', 'prefix', 'depth', 'index'],
    name: 'TreeSnippet',
    computed: {
      // contentItems() {
      //   let contentArray = []
      //   for (let child of this.tree.children) {
      //     if (child.type === 'studentproject') {
      //       contentArray.push(child)
      //     }
      //   }
      //   return contentArray
      // },
      // contextItems() {
      //   let contextArray = []
      //   if (this.tree.children) {
      //
      //   }
      //
      //   return contextArray
      // },
      computedPrefix() {
        return this.depth === 1 ? (this.index + 1) : (this.prefix) + '.' + (this.index + 1)
      }
    },
  }
