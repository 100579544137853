import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  "basic-headline": "",
  class: "w-full"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.size === 'xl')
      ? (_openBlock(), _createElementBlock("h2", {
          key: 0,
          class: _normalizeClass([
      'text-xl',
    ]),
          innerHTML: $props.text
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    ($props.size === 'lg')
      ? (_openBlock(), _createElementBlock("h3", {
          key: 1,
          class: _normalizeClass([
      'text-lg'
    ]),
          innerHTML: $props.text
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    ($props.size === 'md')
      ? (_openBlock(), _createElementBlock("h4", {
          key: 2,
          class: _normalizeClass([
      'text-md'
    ]),
          innerHTML: $props.text
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true),
    ($props.size === 'sm')
      ? (_openBlock(), _createElementBlock("h5", {
          key: 3,
          class: _normalizeClass([
      'text-sm'
    ]),
          innerHTML: $props.text
        }, null, 8, _hoisted_5))
      : _createCommentVNode("", true)
  ]))
}