import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("img", {
    "basic-image": "",
    class: _normalizeClass([
    'object-contain'
    ]),
    src: $props.src
  }, null, 8, _hoisted_1))
}