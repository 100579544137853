import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full flex items-center justify-center" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PageInstitution = _resolveComponent("PageInstitution")
  const _component_SnippetLoading = _resolveComponent("SnippetLoading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($data.abstract && $data.tree)
      ? (_openBlock(), _createBlock(_component_PageInstitution, {
          key: 0,
          abstract: $data.abstract,
          tree: $data.tree
    
        }, null, 8, ["abstract", "tree"]))
      : (_openBlock(), _createBlock(_component_SnippetLoading, { key: 1 }))
  ]))
}