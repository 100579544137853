import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { "block-headline": "" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BasicHeadline = _resolveComponent("BasicHeadline")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BasicHeadline, {
      size: 'sm',
      text: $props.content
    }, null, 8, ["text"])
  ]))
}