

import axios from 'axios'

export default {
  data() {
    return {
      tree: null,
      abstract: null
    }
  },

  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      { immediate: true }
    )
  },
  methods: {
    fetchData() {
      axios
        .get(`https://api.ruralmindshift.org/api/v2/${this.$route.params.id}/fulltree`)
        .then((response) => {
          if (response.data) {
            console.log(response.data)
            this.tree = response.data
          }
      })
      axios
        .get(`https://api.ruralmindshift.org/api/v2/${this.$route.params.id}`)
        .then((response) => {
          if (response.data) {
            console.log(response.data)
            this.abstract = response.data
          }
      })
    },
  },
}

